// Users Types
export const SEARCH_USERS = "SEARCH_USERS";
export const SEARCH_USER = "SEARCH_USER";
export const SEARCH_ALL_USERS = "SEARCH_ALL_USERS";
export const CLEAR_USERS = "CLEAR_USERS";
// Users Types
export const GET_REPOS = "GET_REPOS";
export const SET_LOADING = "SET_LOADING";
export const STATUSCODE = "STATUSCODE";
// Alerts Types
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

